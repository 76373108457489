<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="me">
        <heade></heade>
        <div class="con">
            <div class="box">
                <div class="box_left">
                    <div>
                        <p class="touxiang" v-if="imgBase64.length==0" @click="dianji_fangda">{{mingzi}}</p>
                        <img @click="dianji_fangda" v-if="imgBase64.length!=0" :src="imgBase64[0]" alt>
                        <p class="shangchuan">
                            <input type="file" @change="getImgBasea()">
                            上传头像
                        </p>
                    </div>
                    <ul>
                        <li v-for="(i,index) in left_list" :key="index" @click="dian_list(i)" :class="i.path==path?'xuanzhong':''">{{i.name}}</li>
                    </ul>
                </div>
                <div v-if="fangda" class="da_img" @click="fangda=false">
                    <p class="touxiang" v-if="imgBase64.length==0">{{mingzi}}</p>
                    <img v-if="imgBase64.length!=0" :src="imgBase64[0]">
                </div>
                <div class="right">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <foote></foote>
    </div>
</template>

<script>
import heade from '../Heade'
import foote from '../Foote'
export default {
  name: 'me',
  data () {
    return {
      left_list: [
        {
          name: '我的设置',
          path: '/personset'
        },
        {
          name: '我的认证',
          path: '/myncb'
        },
        {
          name: '我的密码',
          path: '/mypwd'
        },
        {
          name: '我的建议',
          path: '/mysuggestion'
        },
        {
          name: '退出企业',
          path: '/quit'
        }
      ],
      imgBase64: [],
      xin: '',
      lei: '',
      fangda: false,
      mingzi: '智',
      path: ''
    }
  },
  components: { heade, foote },
  created () {
    this.lujing(this.$route.path)
    this.jichu()
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.lujing(val.fullPath)
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    lujing (path) {
      this.path = path
      // 
    },
    jichu () {
      sessionStorage.removeItem('mlbb_me_touxiang')
      if (this.$jichuxinxi().avatar != undefined) {
        this.imgBase64.push(this.$jichuxinxi().avatar)
      }
      if (this.$jichuxinxi().name != undefined) {
        this.mingzi = this.$jichuxinxi().name[0]
      }
    },
    // 获取图片base64实现预览
    getImgBasea () {
      var event = event || window.event
      var file = event.target.files[0]
      var reader = new FileReader()
      const lei = file.name.substring(file.name.lastIndexOf('.'), file.name.length)
      // 转base64
      const arr = []
      const xin = ''
      var _this = this
      reader.onload = function (e) {
        arr.push(e.target.result)
        _this.xin = e.target.result.substring(
          e.target.result.indexOf(',') + 1,
          e.target.result.length
        )
        const obj = {
          xin: _this.xin,
          lei: lei
        }
        _this.$bendi_cunfang_cunchu('mlbb_me_touxiang', obj, '暂时')
      }
      reader.readAsDataURL(file)
      _this.imgBase64 = arr
    },
    // 删除该图片
    // delImga(i,index){
    //     i.imgBase64.splice(index,1)
    // },
    //   返回按钮
    dianji_fanhui () {
      this.$router.push('/Initiateapp')
    },
    dianji_tiaozhuan (index) {
      if (index == 0 && this.path != '/personset') {
        this.$router.push('/personset')
      } else if (index == 1 && this.path != '/myncb') {
        this.$router.push('/myncb')
      } else if (index == 2 && this.path != '/mypwd') {
        this.$router.push('/mypwd')
      } else if (index == 3 && this.path != '/mysuggestion') {
        this.$router.push('/mysuggestion')
      } else if (index == 4 && this.path != '/quit') {
        this.$router.push('/quit')
      }
    },
    // 点击头像放大
    dianji_fangda () {
      this.fangda = true
    },
    dian_list (i) {
      this.$router.push(i.path)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.me {
    background:#E7E8EB;
    min-height: 100vh;
    .con{
        width: 100%;
        background:#E7E8EB;
        display: flex;
        padding-top:1.8rem;
        padding-bottom: 0.46rem; 
        .box{
            width:12rem;
            min-height:6rem;
            margin:0 auto;
            background: #fff;
            display: flex;
            .box_left{
                width: 2.2rem;
                height:100%;
                float: left;
                background-color: #F4F6F8;
                div{
                    margin-top: 0.2rem;
                    .touxiang{
                        height:0.97rem;
                        display: block;
                        width:0.97rem;
                        border-radius: 50%;
                        margin: 0 auto;
                        cursor: pointer;
                        line-height: 0.97rem;
                        background: #1890ff;
                        color:#fff;
                        font-size: 0.4rem;
                        text-align: center;
                        font-family: NSimSun;
                    }
                    img{
                        height:0.97rem;
                        display: block;
                        width:0.97rem;
                        border-radius: 50%;
                        margin: 0 auto;
                        cursor: pointer;
                    }
                    .shangchuan{
                        position: relative;
                        text-align: center;
                        color:#1890ff;
                        font-size: 0.14rem;
                        cursor: pointer;
                        input {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            padding: 0!important;
                            cursor: pointer;
                        }
                    }
                    
                }
                ul{
                    li{
                        padding: 0.16rem 0;
                        width:1.4rem;
                        font-size: 0.14rem;
                        color:#434343;
                        margin: 0.05rem 0;
                        margin: 0 auto;
                        text-align: center;
                        border-radius: 0.06rem;
                        cursor: pointer;
                    }
                    .xuanzhong{
                        color:#9b84dc;
                        background: #e6e9ee;
                    }
                }
            }
            .right{
                float: left;
                width:9.8rem;
            }
        }
        .da_img{
            position: fixed;
            left: 0;
            top:0;
            right:0;
            bottom: 0;
            background-color: rgba(0,0,0,.5);
            z-index: 100;
            cursor: pointer;
            img{
                height: 100%;
                display: block;
                margin: 0 auto;
            }
            .touxiang{
                position: relative;
                top: 1rem;
                left:50%;
                margin-left: -4rem;
                height:8rem;
                width:8rem;
                cursor: pointer;
                line-height: 8rem;
                background: #1890ff;
                color:#fff;
                font-size: 4rem;
                text-align: center;
                border-radius: 0.2rem;
                font-family: NSimSun;
            }
        }
    }
    @media screen and (max-width: 12rem) {
      .con {
        width: 12rem;
      }
    }
}
</style>
